@font-face {
  font-family: 'RigorMortis';
  src: local('RigorMortis'), url(./resources/fonts/RigorMortis.woff2) format('woff2');
}

/* User-select, tap-highlight, and outline CSS below to prevent blue highlighting of panels for Chrome on Android, as per these answers:
https://stackoverflow.com/questions/21003535/anyway-to-prevent-the-blue-highlighting-of-elements-in-chrome-when-clicking-quic */

* {
  box-sizing: border-box;
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard syntax */
  -webkit-tap-highlight-color: transparent;
}

:focus {
  outline: none !important;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #111;
  box-shadow: inset 5px 5px 100px rgba(0, 0, 0, 0.5);
  -webkit-font-smoothing: antialiased;
  overflow: auto;
  position: fixed;
  overscroll-behavior-y: none;
}

#root::after {
  content: ""; overflow: hidden;
  background-image: url(./resources/images/png/white-paperboard.png);
  background-size: 80px;
  background-blend-mode: difference;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: 1;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  margin: 14px 0 5px 5px;
  z-index: 1000;
}

.grid .item {
  margin: 0 0 5px 7px;
  cursor: pointer;
  position: relative;
  width: calc(50vw - 20px);
  height: calc(50vw - 20px);
  background: #444;
  background-image: url(./resources/images/png/grid.png);
  background-size: 310px;
  background-blend-mode: difference;
  -webkit-mask-image: url(./resources/images/png/panel-b.png);
  -webkit-mask-size: 100% 100%;
  z-index: 1000;
  
}

canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
  opacity: 1;
  transition: opacity 1s ease-in-out !important;
}

.loaded {
  opacity: 1 !important;
}

.editora {
  position: fixed;
  top: -7px;
  left: -7px;
  width: 60px;
  cursor: grab;
  z-index: 2000;
  cursor: help;
}

.miedo {
  position: fixed;
  left: 2px;
  top: 4px;
  width: 50vw;
  height: 50vw;
  pointer-events: none;
  z-index: 2000;
}

.titles {
  position: fixed;
  bottom: 5px;
  right: 5px;
  z-index: 2000;
}

.titles a {
  padding: 0 10px 4px;
  background-color: #222;
  -webkit-mask-image: url(./resources/images/png/panel-b.png);
  -webkit-mask-size: 100% 100%;
  text-decoration: none;
  color: #eee;
  font-family: 'RigorMortis';
  font-size: 28px;
  letter-spacing: 2px;
  transition: color 240ms ease-out;
  margin-right: 5px;
}

.titles a:hover,
.titles a.active {
  color: #FCD02C;
  }
  
.modal-overlay {
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.95);
  background-image: url(./resources/images/png/grid.png);
  background-blend-mode: exclusion;
  background-size: 80px;
  display: flex;
  padding: 1em;
  position: fixed;
  /* text-transform: uppercase; */
  font-family: 'RigorMortis';
  font-size: 17px;
  line-height: 1.25em;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  cursor: row-resize;
}

.modal {
  color: #eee;
  background-color: black;
  padding: 1rem 2.2rem;
  max-width: 640px;
  max-height: 94vh;
  text-align: left;
  -webkit-mask-image: url(./resources/images/png/panel-b.png);
  -webkit-mask-size: 100% 100%;
}

.modal img {
  max-height: 20vh;
}

.modal em {
  color: #FCD02C;
}

button {
  z-index: 6000;
  position: fixed;
  top: 0;
  right: 50%;
}

.GithubCorner {
  z-index: 5000;
  position: fixed;
  top: 0;
  right: 0;
}
  
.GithubCorner__svg {
  height: 80px;
  width: 80px;
}
.GithubCorner:hover .GithubCorner__octo-arm {
    animation: octocat-wave 560ms ease-in-out
}

@keyframes octocat-wave {
    0%, 100% {
        transform: rotate(0)
    }
    20%, 60% {
        transform: rotate(-25deg)
    }
    40%, 80% {
        transform: rotate(10deg)
    }
}

@media (max-width:500px) {
    .GithubCorner:hover .GithubCorner__octo-arm {
        animation: none
    }
    .GithubCorner .GithubCorner__octo-arm {
        animation: octocat-wave 560ms ease-in-out
    }
}

@media only screen and (max-width: 550px) {
  .grid {
    margin: 14px 14px 7px 7px;
  }
  .grid .item ,
  .miedo {
    width: 100vw;
    height: 100vw;
  }
  .miedo {
    /* bottom: -50vw; */
  }
  .modal-overlay {
    font-size: 15px;
  }
}

@media only screen and (max-width: 450px) {
  .modal-overlay {
    font-size: 13px;
  }
}

@media only screen and (max-height: 600px) {
  .modal-overlay {
    font-size: 15px;
  }
}

@media only screen and (max-height: 500px) {
  .modal-overlay {
    font-size: 14px;
  }
}